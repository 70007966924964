@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@100;300;400;700&display=swap');

@font-face {
  font-family: 'Cormorant Garamond Italic';
  src: url('webfont/CormorantGaramond-Italic.woff2') format('woff2'),
  url('webfont/CormorantGaramond-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Garamond BoldItalic';
  src: url('webfont/CormorantGaramond-BoldItalic.woff2') format('woff2'),
  url('webfont/CormorantGaramond-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Garamond Bold';
  src: url('webfont/CormorantGaramond-Bold.woff2') format('woff2'),
  url('webfont/CormorantGaramond-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Garamond Light';
  src: url('webfont/CormorantGaramond-Light.woff2') format('woff2'),
  url('webfont/CormorantGaramond-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Garamond LightItalic';
  src: url('webfont/CormorantGaramond-LightItalic.woff2') format('woff2'),
  url('webfont/CormorantGaramond-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Garamond Regular';
  src: url('webfont/CormorantGaramond-Regular.woff2') format('woff2'),
  url('webfont/CormorantGaramond-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Garamond MediumItalic';
  src: url('webfont/CormorantGaramond-MediumItalic.woff2') format('woff2'),
  url('webfont/CormorantGaramond-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Garamond Medium';
  src: url('webfont/CormorantGaramond-Medium.woff2') format('woff2'),
  url('webfont/CormorantGaramond-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Garamond SemiBoldItalic';
  src: url('webfont/CormorantGaramond-SemiBoldItalic.woff2') format('woff2'),
  url('webfont/CormorantGaramond-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre Regular';
  src: url('webfont/ProzaLibre-Regular.woff2') format('woff2'),
  url('webfont/ProzaLibre-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Cormorant Garamond SemiBold';
  src: url('webfont/CormorantGaramond-SemiBold.woff2') format('woff2'),
  url('webfont/CormorantGaramond-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Copperplate Gothic';
  src: url('webfont/CopperplateGothic-Light.eot');
  src: url('webfont/CopperplateGothic-Light.eot?#iefix') format('embedded-opentype'),
  url('webfont/CopperplateGothic-Light.woff2') format('woff2'),
  url('webfont/CopperplateGothic-Light.woff') format('woff'),
  url('webfont/CopperplateGothic-Light.svg#CopperplateGothic-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate Gothic';
  src: url('webfont/CopperplateGothic-Light_1.eot');
  src: url('webfont/CopperplateGothic-Light_1.eot?#iefix') format('embedded-opentype'),
  url('webfont/CopperplateGothic-Light_1.woff2') format('woff2'),
  url('webfont/CopperplateGothic-Light_1.woff') format('woff'),
  url('webfont/CopperplateGothic-Light_1.ttf') format('truetype'),
  url('webfont/CopperplateGothic-Light_1.svg#CopperplateGothic-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}



html, body {
  margin: 0 0 0 0;
  padding: 0;
  background-color: #000!important;
  background-size: cover!important;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  font-family: 'Cormorant Garamond Regular';

}


.mat-drawer-container, .mat-sidenav-container {
  -webkit-background-size: cover!important;
  -moz-background-size: cover!important;
  -o-background-size: cover!important;
  background-size: cover!important;

  background-repeat: no-repeat!important;
  background-attachment: fixed!important;

  background-position: unset!important;
  background-attachment: scroll!important;
  background-size: cover!important;
  width: 100%;
  height: 100%;
}

.main-content {
  margin: 0 0 0 0!important;
}

.mat-drawer-container {
  background-color: transparent;
}



.containerScroll {
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh;

}

*:focus {
  outline: none !important;
}

.fixednavbar  {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background-color: transparent!important;
}

.mat-grid-tile-content {
  border: solid 2px #d6b968;

}

a {
  color: white;
  text-decoration: none;
  font-weight: normal;
  &:hover {
    text-decoration: underline;
  }
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~lightgallery/scss/lightgallery';

.w100 {
  width: 100%;
}

.form-card {
  margin-bottom: 20px;
}



.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
  transform: none !important;
}


.fixednavbar  .mat-button-wrapper .mat-icon {

  width: auto!important;

  &:hover {
    color: #e6d4a4;
  }
}



.mat-progress-spinner  {
  position: fixed!important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #ffffff;
}


.noResults {
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: 50px;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.h1Container {
  margin-bottom: 20px;
  text-align: center;

  h1 {
    color: #fff;
    font-size: 1.5rem!important;
    margin: 0 0 20px 0;
    margin-bottom: 20px;
    text-align: center ;
    font-family: 'Cormorant Garamond Regular';

  }
}
.backLink {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'Copperplate Gothic';
  background-color: transparent;

}


mat-bottom-sheet-container {
  background-color: rgba(0,0,0,0.8)!important;
  border:solid 1px #C79E26!important;
  h3 {
    font-family: 'Copperplate Gothic';
    color: #fff!important;
    text-align: center;

    mat-icon {
      color:#C79E26
    }

  }
}

.cdk-overlay-container {


  .mat-snack-bar-container {
    background-color: rgba(255,255,255,0.7);
    color:#000;
    border:none;
    button {
      color: #fff;
    }
  }
}

::ng-deep .cdk-overlay-container {
  z-index: 99999999 !important;
}

.mat-bottom-sheet-container {
  @include media-breakpoint-up(md) {
    min-width: 400px!important;
  }

}






.mat-dialog-container {
  background-color: rgba(0,0,0,0.7)!important;
  box-shadow: none!important;
  padding: 0 0 0 0;
  text-align: center;
  border:solid 2px #C79E26!important;
  color: #fff;

  .mat-card {
    background-color: transparent!important;
    box-shadow: none!important;

    color:#fff;

    .mat-card-subtitle {
      font-family: 'Copperplate Gothic';
      font-size: 17px;
      color:#fff;
    }


  }
}


.pink {
  color: #C79E26!important;
  &:hover, &:focus {
    color: #e6d4a4!important;

  }
}



.sidebarright {
  padding: 15px;
  background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,0.2) 20%, rgba(255,255,255,0.2) 80%, rgba(0,0,0,0.7) 100%);

  max-width: 150px;
  h3 {
    color: #fff;
    font-family: 'Copperplate Gothic';
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    mat-icon {
      transform: scale(1.2);
      text-align: center;
      margin: auto;
      display: block;
      margin-bottom:5px;
      margin-top: 20px;
    }
  }

  button.closeRight {
    position: fixed;
    right: 135px;
    top: 20px;
    z-index: 9999999;
    background-color: transparent;
    border: none;

    mat-icon {
      transform: scale(1.3);
      color: #fff;

    }
  }
  button.deleteBtn {
    position: fixed;
    right: 70px;
    bottom: 10px;
    z-index: 9999999;
    background-color: transparent;
    border: none;

    mat-icon {
      transform: scale(1.3);
      color: #fff;

    }
  }
}


/*
.mat-drawer.mat-drawer-side {
  z-index: 1!important;
}

.mat-drawer-content {
  z-index: auto!important;
}

.mat-drawer-container {
  z-index: auto!important;
}
*/


.lg-backdrop {
  background-color: rgba(0,0,0,0.9)!important;
}
.session-modal-container .modal-backdrop {
  z-index: 10!important;
}
.session-modal-container .session-dialog {

  z-index: 11!important;
}
.session-modal-container .modal-dialog {
  margin: 0 0 0 0;
}

.galleryDetail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
}


.sidebarright {
  min-width: 180px;
  padding-top: 100px;
  padding-bottom: 60px;
}
.topCart {
  position: fixed;
  top: 20px;
  right: 0;
  max-width: 180px;
  width: 180px;
}

.lg-next:before {
  color: #d6b968;
}

.lg-next:hover:before,
.lg-next:focus:before {
  color: #C79E26;
}

.lg-next:hover,
.lg-next:focus {
  color: #C79E26;
}

.lg-prev:after {
  color: #d6b968;
}

.lg-prev:hover:after,
.lg-prev:focus:after {
  color: #C79E26;
}

.lg-prev:hover,
.lg-prev:focus {
  color: #C79E26;
}
