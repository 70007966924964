@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$indigo-palette: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  // ... continues to 900
  contrast: (
    50: rgba(#C79E26, 0.87),
    100: rgba(#C79E26, 0.87),
    200: rgba(#C79E26, 0.87),
    300: white,
    // ... continues to 900
  )
);

$bright-navy-blue-palette: (
  50: #C79E26,
  100: #C79E26,
  200: #C79E26,
  300: #C79E26,
  400: #C79E26,
  500: #C79E26,
  600: #C79E26,
  700: #C79E26,
  800: #C79E26,
  900: #C79E26,
  A100: #C79E26,
  A200: #C79E26,
  A400: #C79E26,
  A700: #C79E26,
  contrast: (
    50: rgba(#C79E26, 0.87),
    100: rgba(#C79E26, 0.87),
    200: rgba(#C79E26, 0.87),
    300: rgba(#C79E26, 0.87),
    400: rgba(#C79E26, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(#C79E26, 0.87),
    A200: rgba(#C79E26, 0.87),
    A400: white,
    A700: white,
  )
);
$my-primary: mat-palette($bright-navy-blue-palette);
$my-accent:  mat-palette($mat-pink);
$my-warn:    mat-palette($mat-deep-orange);
$my-theme: mat-light-theme($my-primary, $my-accent, $my-warn);
@include angular-material-theme($my-theme);


.mat-form-field-label {
  color: #fff;
}
